import React, { useEffect } from "react";
import Benefits from "./Benefits";
import Header from "./Header";
import About from "./About";
import Value from "./Value";
import FooterPage from "./FooterPage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SignedOutMenu from "../nav/SignedOutMenu";
import { openModal } from "../../app/common/modals/modalReducer";

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, isMobileDevice, token } = useSelector(
    (state) => state.auth
  );
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { pathname } = useSelector((state) => state.router.location);

  useEffect(() => {
    if (pathname === "/about") return;
    if (!isAuthenticated) {
      if (token) {
        dispatch(
          openModal({
            modalType: "ActivateAccountModal",
            modalProps: currentUserProfile,
          })
        );
      } else return;
    } else {
      history.push("/clubs");
    }
    // eslint-disable-next-line
  }, [isAuthenticated, history, token, pathname]);

  return (
    <div style={{ marginLeft: isAuthenticated && !isMobileDevice ? 100 : 0 }}>
      <Header />
      <About />
      <Benefits />
      <Value />
      <FooterPage />
      {!(isAuthenticated || isMobileDevice) && <SignedOutMenu />}
    </div>
  );
};

export default AboutUsPage;
